import React from 'react';
import { AppConfig } from './app/app-config.model';
import { buildAuthService } from './app/shared/api/auth/authservice';

export const checkAuth = (config: AppConfig) => {
  const authService = buildAuthService(config);
  if (
    !authService.isAuthenticated() &&
    !window.location.pathname.includes('callback')
  ) {
    authService.signinRedirect();
  } else {
    import('./bootstrap').then(bootstrap => {
      bootstrap.default(config, authService);
    });
  }
};
