import axios from 'axios';

import './styles/index.scss';
// import * as serviceWorker from './serviceWorker';
import { checkAuth } from './checkAuth';

axios
  .get(process.env.REACT_APP_CONFIG_URL!)
  .then(response => checkAuth(response.data))
  .catch(err => {
    document.getElementById('root')!.innerHTML = `
      <p>
        Failed to load application config, please try refresh page, if error persists please contact administrator.
      </p>
    `;
    console.error(
      'Cannot load config from ',
      process.env.REACT_APP_CONFIG_URL,
      'due',
      err
    );
  });
