import { UserManager, Log, User } from 'oidc-client';
import { AppConfig } from '../../../app-config.model';
import { noop } from '../../utils/noop';

export interface AuthService {
  signinRedirectCallback: () => void;
  logout: () => void;
  signoutRedirectCallback: () => void;
  isAuthenticated: () => boolean;
  signinRedirect: () => void;
  createSigninRequest: () => void;
  parseJwt: (token: string) => any;
  getUser: () => Promise<User | null>;
  userManager: UserManager;
  signinSilentCallback: () => void;
}

export function buildAuthService(config: AppConfig): AuthService {
  console.log('window.location.origin', window.location.origin, config);
  const userManager = new UserManager({
    authority: config.stsAuthority,
    client_id: config.clientId,
    redirect_uri: `${config.clientRoot}callback`,
    response_type: 'code',
    response_mode: 'query',
    scope: config.clientScope,
    post_logout_redirect_uri: `${config.clientRoot}logout`,
    revokeAccessTokenOnSignout: true,
    // popup_redirect_uri: `${config.clientRoot}callback`,
    silent_redirect_uri: `${config.clientRoot}silent-redirect`,
    // automaticSilentRenew: true,
    // popupWindowFeatures:
    //   'location=no,toolbar=no,width=1200,height=850,resizable=no,left=50,top=100',
    accessTokenExpiringNotificationTime: 30,
  });
  // Logger
  Log.logger = console;
  Log.level = Log.DEBUG;

  // userManager.events.addAccessTokenExpiring(function(){
  //   console.log('token expiring...');
  // });

  const signinRedirectCallback = () => {
    userManager.signinRedirectCallback().then(() => {
      window.location.replace(
        `${config.clientRoot}${localStorage
          .getItem('redirectUri')
          ?.replace('/', '')}`
      );
    });
  };

  const getUser = async () => {
    const user = await userManager.getUser();
    return user;
  };

  const parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  const signinRedirect = () => {
    if (!window.location.pathname?.includes('logout')) {
      localStorage.setItem('redirectUri', window.location.pathname);
      window.location.search
        .replace('?', '')
        .split('&')
        .forEach(param => {
          const par = param.split('=');
          localStorage.setItem(par[0], par[1]);
        });
    }

    return userManager.signinRedirect().catch(error => {
      console.error('error in signin', error);
      window.location.replace('/');
    });
  };

  const isAuthenticated = () => {
    const token = sessionStorage.getItem(
      `oidc.user:${config.stsAuthority}:${config.clientId}`
    );
    const oidcStorage = token ? JSON.parse(token) : undefined;
    return !!oidcStorage && !!oidcStorage.access_token;
  };

  const createSigninRequest = () => {
    return userManager.createSigninRequest();
  };

  const logout = () => {
    userManager.signoutRedirect().catch(error => {
      sessionStorage.clear();
      window.location.replace('/');
    });
    userManager.clearStaleState();
  };

  const signoutRedirectCallback = () => {
    userManager.signoutPopupCallback(false).then(reponse => {
      localStorage.clear();
      sessionStorage.clear();
    });
    userManager.clearStaleState();
  };

  const signinSilentCallback = () => {
    userManager.signinSilentCallback();
  };

  return {
    signinRedirectCallback: signinRedirectCallback,
    logout: logout,
    signoutRedirectCallback: signoutRedirectCallback,
    isAuthenticated: isAuthenticated,
    signinRedirect: signinRedirect,
    createSigninRequest: createSigninRequest,
    parseJwt: parseJwt,
    getUser: getUser,
    userManager: userManager,
    signinSilentCallback: signinSilentCallback,
  };
}

export function buildAuthServiceMock(): AuthService {
  return {
    signinRedirectCallback: () => noop,
    logout: () => noop,
    signoutRedirectCallback: () => noop,
    isAuthenticated: () => true,
    signinRedirect: () => noop,
    createSigninRequest: () => noop,
    parseJwt: (token: string) => noop,
    getUser: () => Promise.resolve(null),
    userManager: {} as UserManager,
    signinSilentCallback: () => noop,
  };
}
